@import '~video-react/styles/scss/video-react';

$primary-bg-color: #343a40;
$secondary-bg-color: #495057;
$hover-bg-color: #6c757d;
$light-bg-color: #f1f3f5;
$primary-text-color: #ffffff;
$secondary-text-color: #28a745;
$border-color: #495057;
$button-bg-color: #007bff;
$button-hover-color: #0056b3;

$courses-bg-color: #f8f9fa;
$course-card-bg-color: #ffffff;
$course-card-border-color: #dee2e6;
$course-card-shadow-color: rgba(0, 0, 0, 0.1);
$course-icon-color: #007bff;
$course-text-color: #343a40;

.training-component {
    width: 100%;
    display: flex;
    height: 100vh;
    font-family: $font-family;
    overflow: hidden;
    background-color: $black;

    .video-navigator {
        width: 40%;
        color: $primary-text-color;
        border-right: 1px solid $border-color;
        overflow-y: auto;

        .training-title {
            padding: 10px;
            font-size: 15px;
            margin-bottom: 20px;
            text-align: center;
            // background-color: #343a40;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        ul {
            list-style: none;
            padding: 20px;
            // background-color: #343a40;

            li {
                padding: 10px 0;

                .video-card {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: $secondary-bg-color;
                    padding: 10px;
                    border-radius: 5px;
                    transition: background-color 0.3s;
                    cursor: pointer;

                    &:hover {
                        background-color: $hover-bg-color;
                    }

                    span {
                        color: $primary-text-color;
                        font-size: 16px;
                        margin-left: 10px;
                    }

                    .completed-icon {
                        color: $secondary-text-color;
                        font-size: 18px;
                    }

                    &.active {
                        background: #007bff;
                    }
                }
            }
        }
    }

    .video-container {
        width: 100%;
        background-color: $light-bg-color;
        overflow-y: auto;

        .container-header {
            display: flex;
            flex-direction: row;
            height: 80px;
            border-bottom: 1px solid #343a40;

            .previus-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60%;

                span {
                    margin-left: 20px;
                }

                &:hover {
                    background-color: #343a4085;
                    cursor: pointer;
                }
            }

            .center-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                &::after {
                    content: '';
                    width: 1px;
                    height: 100%;
                    background-color: #343a40;
                }

                &::before {
                    content: '';
                    width: 1px;
                    height: 100%;
                    background-color: #343a40;
                }
            }

            .next-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60%;

                span {
                    margin-right: 20px;
                }

                &:hover {
                    background-color: #343a4085;
                    cursor: pointer;
                }
            }
        }

        .video-title {
            margin-top: 10px;
            font-size: 30px;
            color: $primary-bg-color;
            text-align: center;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .buttons-container {
            padding: 10px;
            margin: 15px 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 10px;
        }

        .desciption-container {
            padding: 20px;
            margin-top: 20px;
            border-radius: $border-radius;
            background-color: #4950574f;

            h3 {
                font-size: 20px;
            }

            span {
                font-size: 15px;
            }
        }
    }
}