/* ============================================================================
	Vars
============================================================================ */
$font-family:roboto, Helvetica, Arial, sans-serif;
$primary-color:#c69e62;
$primary-color-hover:#816f46;
$secondary-color:#42424a;
$secondary-color-hover:rgba(66, 66, 74, 0.7);
$grid-width:1200px;
$shadow:0 4px 10px rgba($primary-color, 0.1);
$shadow-hover:0 4px 20px rgba($primary-color, 0.4);
$border-radius:6px;
$form-vertical-label-padding:0 0 6px;
$layout-body-background:#eef2f7;
$input-height:40px;
$black:#000000;

// Main drawer
$main-drawer-background:$black;
$main-drawer-color:rgba(255, 255, 255, 0.65);
$main-drawer-color-hover:#fff;
$main-drawer-color-active:#fff;
$main-drawer-background-active:rgba(0, 0, 0, 0.65);
$main-drawer-arrow-color:#fff;
$main-drawer-submenu-background:#838383;
$main-drawer-submenu-color:#fff;
$main-drawer-submenu-color-hover:#fff;
$main-drawer-submenu-color-active:#fff;
$main-drawer-submenu-background-active:rgba(0, 0, 0, 0.137);
