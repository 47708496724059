.page-franchisee-show {
	display:flex;
	flex-direction:row;
	.item-franchisee-button {
		width:150px;
		height:150px;
		margin-right:20px;
		margin-top:20px;
		border:1px solid rgba(66, 66, 74, 0.35);
		border-radius:10px;
		background:linear-gradient(195deg, #42424a, #191919);
		box-shadow:5px 5px 5px rgba(0, 0, 0, 0.21);
		display:flex;
		justify-content:center;
		align-items:center;
		flex-direction:column;
		color:#fff;

		i {
			font-size:1.5rem;
		}

		span {
			margin-top:15px;
			font-size:1rem;
		}

		&:hover {
			opacity:0.9;
			cursor:pointer;
		}
	}
}