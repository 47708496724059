.page-login {
	background-color:#f2f2f2;
	width:100%;
	height:100vh;
	display:flex;
	justify-content:center;
	align-items:center;

	.container-login {
		border-radius:10px;
		width:400px;
		height:70%;
		background-color:#2a2a2a;
		box-shadow:5px 5px 10px 3px rgba(100, 100, 100, 0.26);

		display:flex;
		flex-direction:column;
		justify-content:center;
		align-items:center;

		.container-logo {
			width:300px;

			img {
				width:100%;
			}
		}

		.side-login {
			padding:30px;
			width:100%;

			&.logo {
				background-color:green;
			}
		}

		a {
			text-align:right;
			color:#fff;
		}
	}
}