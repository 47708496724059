.page-kanban {
    background-color: whitesmoke;
    background-image: url("../../assets/images/logo-ct.png");
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;

    .loading-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .react-kanban-board {
        height: 100%;
    }

    .react-kanban-column {
        /* Defina uma altura máxima para que o scroll seja ativado apenas se o conteúdo ultrapassar esse valor */
        max-height: calc(100vh - 132px);
        /* ou o valor que melhor se ajuste ao seu layout */
        overflow-y: auto;

        /* Oculta a scrollbar no Firefox */
        scrollbar-width: none;
    }

    /* Oculta a scrollbar no Chrome, Safari e Opera */
    .react-kanban-column::-webkit-scrollbar {
        display: none;
    }

    .react-kanban-column {
        background: rgba(255, 255, 255, 0.401);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        // backdrop-filter: blur(19.1px);
        -webkit-backdrop-filter: blur(19.1px);
        border: 1px solid rgba(255, 255, 255, 1);
    }

    .column-header {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .card-kanban {
        width: 350px !important;
        // z-index: 9999;
        // color: #fff !important;
        width: 100%;
        margin-bottom: 20px;
        background: rgba(255, 255, 255, 0.444);
        box-shadow: 0 8px 32px 0 rgba(117, 112, 46, 0.37);
        backdrop-filter: blur(13.5px);
        -webkit-backdrop-filter: blur(13.5px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);

        .ant-card-extra {
            .card-button {
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 99999;
                width: 20px;
                cursor: pointer !important;

                &:hover {
                    background-color: rgba(133, 133, 133, 0.1);
                }
            }

            // margin-right: -20px !important;
        }


        .item-card {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            border-bottom: 1px #000 dotted;

            .title {
                font-size: 14px;
                margin-bottom: 5px;
            }

            .text {
                text-overflow: ellipsis ellipsis;
                font-size: 16px;
                margin-left: 10px;
                font-weight: 600;
                margin-bottom: 5px;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}