.training-users-component {
    .courses-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
        padding: 20px;
    }

    .course-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background-color: $course-card-bg-color;
        border: 1px solid $course-card-border-color;
        border-radius: 10px;
        box-shadow: 0 4px 6px $course-card-shadow-color;
        transition: transform 0.2s, box-shadow 0.2s;

        &:hover {
            cursor: pointer;
            transform: translateY(-5px);
            box-shadow: 0 8px 12px $course-card-shadow-color;
        }

        .course-icon {
            font-size: 40px;
            color: $course-icon-color;
            margin-bottom: 10px;
        }

        .course-name {
            font-size: 18px;
            font-weight: bold;
            color: $course-text-color;
            text-align: center;
            margin-bottom: 5px;
        }

        .course-lessons {
            font-size: 14px;
            color: $course-text-color;
        }
    }

    .courses-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
        padding: 20px;
    }
    
    .course-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background-color: $course-card-bg-color;
        border: 1px solid $course-card-border-color;
        border-radius: 10px;
        box-shadow: 0 4px 6px $course-card-shadow-color;
        transition: transform 0.2s, box-shadow 0.2s;
    
        &:hover {
            cursor: pointer;
            transform: translateY(-5px);
            box-shadow: 0 8px 12px $course-card-shadow-color;
        }
    
        .course-icon {
            font-size: 40px;
            color: $course-icon-color;
            margin-bottom: 10px;
        }
    
        .course-name {
            font-size: 18px;
            font-weight: bold;
            color: $course-text-color;
            text-align: center;
            margin-bottom: 5px;
        }
    
        .course-lessons {
            font-size: 14px;
            color: $course-text-color;
        }
    }    
}